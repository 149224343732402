export const WeeklyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="50"
    height="50"
    viewBox="0 0 24 24"
  >
    <polygon
      style={{
        fill: "none",
        stroke: "#000000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
      points="17.5,18 17.5,20.5 15,20.5 12,23.5 9,20.5 6.5,20.5 6.5,18 4,15.5 6.5,13 6.5,10.5 9,10.5 12,7.5 15,10.5 17.5,10.5 17.5,13 20,15.5"
    />
    <polyline
      style={{
        fill: "none",
        stroke: "#000000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
      points="7.5,0.5 7.5,5.5 12,7.5 16.5,5.5 16.5,0.5"
    />
    <line
      style={{
        fill: "none",
        stroke: "#000000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
      x1={5.5}
      y1={0.5}
      x2={18.5}
      y2={0.5}
    />
    <line
      style={{
        fill: "none",
        stroke: "#000000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
      x1={10.5}
      y1={0.5}
      x2={10.5}
      y2={6.75}
    />
    <line
      style={{
        fill: "none",
        stroke: "#000000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
      x1={13.5}
      y1={0.5}
      x2={13.5}
      y2={6.75}
    />
    <polygon
      style={{
        fill: "none",
        stroke: "#000000",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
      points="14.5,17 12,18.5 9.5,17 9.5,14 12,12.5 14.5,14"
    />
  </svg>
);
export const MonthIcon = () => (
  <svg width="50" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_15_406)">
      <path d="M20 39.1667V37.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M29.4283 35.2617L28.25 34.0834"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M33.3327 25.8334H31.666" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.66602 25.8334H8.33268" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.5742 35.2617L11.7526 34.0834"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M26.8274 7.5H13.1758" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 0.833374H30" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.6669 16.9267L10.0002 0.833374L5.05686 6.48337C4.80512 6.7711 4.65995 7.13662 4.64569 7.51865C4.63143 7.90069 4.74894 8.27602 4.97852 8.58171L13.3119 19.6934"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2734 17.06L30.0001 0.833374L34.9434 6.48337C35.1952 6.7711 35.3403 7.13662 35.3546 7.51865C35.3689 7.90069 35.2514 8.27602 35.0218 8.58171L26.4284 20.0384"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4023 25C11.4023 27.2101 12.2803 29.3297 13.8431 30.8925C15.4059 32.4553 17.5255 33.3333 19.7357 33.3333C21.9458 33.3333 24.0654 32.4553 25.6282 30.8925C27.191 29.3297 28.069 27.2101 28.069 25C28.069 22.7898 27.191 20.6702 25.6282 19.1074C24.0654 17.5446 21.9458 16.6666 19.7357 16.6666C17.5255 16.6666 15.4059 17.5446 13.8431 19.1074C12.2803 20.6702 11.4023 22.7898 11.4023 25Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15_406">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const YearIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={3}
    stroke="#000000"
    fill="none"
  >
    <path d="M16.9,10.79H47a.29.29,0,0,1,.29.28c.11,2.61.51,22.87-13,28.77v7.58H29.7V39.84S17,35.21,16.6,11.1A.3.3,0,0,1,16.9,10.79Z" />
    <path d="M47.33,14.27h2.89a3.54,3.54,0,0,1,3.54,3.55v4a7.07,7.07,0,0,1-3.13,5.88l-8.9,6" />
    <path d="M16.73,14.27h-3a3.54,3.54,0,0,0-3.54,3.55v4a7.07,7.07,0,0,0,3.13,5.88L23,34.06" />
    <rect x={24.89} y={47.42} width={14.62} height={5.79} />
    <path d="M32.16,16.16l2.25,4.45a.15.15,0,0,0,.14.1l5,.72a.18.18,0,0,1,.1.32L36,25.2a.18.18,0,0,0-.06.17l.86,4.88a.19.19,0,0,1-.27.2l-4.49-2.32a.22.22,0,0,0-.16,0l-4.49,2.32a.19.19,0,0,1-.27-.2L28,25.37A.18.18,0,0,0,28,25.2l-3.61-3.45a.18.18,0,0,1,.1-.32l5-.72a.15.15,0,0,0,.14-.1l2.25-4.45A.18.18,0,0,1,32.16,16.16Z" />
  </svg>
);

export const OrangeCheckMark = () => (
  <svg width="40" height="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#c95944" />
    <path d="M 8,17 L 12,21 L 21,8" fill="none" stroke="white" strokeWidth="2" />
  </svg>
);

export const quoteSvg = (
  <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.1539 0.111816H22.9297C21.9107 0.111816 21.0836 0.938863 21.0836 1.9579V13.1821C21.0836 14.2012 21.9107 15.0282 22.9297 15.0282H28.3018C28.2317 17.9672 27.5486 20.3191 26.2564 22.0876C25.2373 23.4833 23.694 24.6389 21.6301 25.5509C20.6812 25.9681 20.2714 27.0905 20.7144 28.0284L22.0436 30.8344C22.4719 31.7353 23.5315 32.1304 24.4472 31.7353C26.8914 30.6793 28.9554 29.3427 30.639 27.7182C32.6919 25.7355 34.0985 23.498 34.8591 21.0021C35.6197 18.5062 36 15.102 36 10.7822V1.9579C36 0.9389 35.1729 0.111816 34.1539 0.111816Z"
      fill="#f26b0b"
    />
    <path
      d="M3.91504 31.7279C6.32972 30.6756 8.38257 29.3391 10.0773 27.7182C12.1486 25.7355 13.5627 23.5054 14.3233 21.028C15.0839 18.5505 15.4641 15.1353 15.4641 10.7822V1.9579C15.4641 0.938863 14.6371 0.111816 13.618 0.111816H2.39383C1.37479 0.111816 0.547744 0.938863 0.547744 1.9579V13.1821C0.547744 14.2012 1.37479 15.0282 2.39383 15.0282H7.76594C7.69579 17.9672 7.01274 20.3191 5.72048 22.0876C4.70144 23.4833 3.15811 24.6389 1.09419 25.5509C0.145297 25.9681 -0.264534 27.0905 0.178527 28.0284L1.50402 30.827C1.93235 31.7279 2.99938 32.1267 3.91504 31.7279Z"
      fill="#f26b0b"
    />
  </svg>
);

export const GreenCheckMarkWithoutBackground = () => (
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 48 48"
    version="1"
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 48 48"
  >
    <polygon fill="#43A047" points="40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9" />
  </svg>
);
